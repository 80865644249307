.graph{
    color: white;
    font-size: smaller;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 1em;
    top: 80px;
    right: 10px;
    text-align: right;
}